.navbar{
  .navbar-nav{
    .nav-link{
      i.fa + p,
      i.now-ui-icons + p{
        margin-left: 6px;
      }
    }
  }
}
@media screen and (max-width: 991px){
  .navbar .dropdown.show .dropdown-menu, .navbar .dropdown .dropdown-menu{
    position: static !important;
  }
}
