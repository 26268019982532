// Mixin for generating new styles
@mixin btn-styles($btn-color, $btn-states-color) {
    background-color: $btn-color;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus,
    &:active:hover,
    &.active:hover,
    .show > &.dropdown-toggle,
    .show > &.dropdown-toggle:focus,
    .show > &.dropdown-toggle:hover {
        background-color: $btn-states-color;
        color: $white-color;
        box-shadow: none;
        border-color: $btn-states-color;
    }

    &:not([data-action]):not([class*="btn-outline-"]):hover{
        box-shadow:  0 3px 8px 0 rgba(0,0,0, 0.17);
    }

    &.disabled,
    &:disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            background-color: $btn-color;
            border-color: $btn-color;
        }
    }

    // btn-neutral style
    @if $btn-color == $white-color{
        color: $primary-color;

        &.btn-danger{
            color: $danger-color;

            &:hover,
            &:focus,
            &:active,
            &:active:focus{
                color: $danger-states-color !important;
            }
        }

        &.btn-info{
            color: $info-color;

            &:hover,
            &:focus,
            &:active,
            &:active:focus{
                color: $info-states-color !important;
            }
        }

        &.btn-warning{
            color: $warning-color;

            &:hover,
            &:focus,
            &:active,
            &:active:focus{
                color: $warning-states-color !important;
            }
        }

        &.btn-success{
            color: $success-color;

            &:hover,
            &:focus,
            &:active,
            &:active:focus{
                color: $success-states-color !important;
            }
        }

        &.btn-default{
            color: $default-color;

            &:hover,
            &:focus,
            &:active,
            &:active:focus{
                color: $default-states-color !important;
            }
        }

        &.active,
        &:active,
        &:active:focus,
        &:active:hover,
        &.active:focus,
        &.active:hover,
        .show > &.dropdown-toggle,
        .show > &.dropdown-toggle:focus,
        .show > &.dropdown-toggle:hover {
            background-color: $white-color;
            color: $primary-states-color !important;
            box-shadow: none;
        }

        &:hover,
        &:focus{
            color: $primary-states-color;

            &:not(.nav-link){
                box-shadow: none !important;
            }

        }

    } @else {
        color: $white-color;
    }

    &.btn-link{
        color: $btn-color;

        &:hover,
        &:focus,
        &:active{
            background-color: $transparent-bg;
            color: $btn-states-color;
            text-decoration: none;
            box-shadow: none;
        }
    }
}

@mixin outline-buttons($btn-color, $btn-states-color) {
  color: $btn-color;
  border-color: $btn-color;

  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  &:active:hover,
  &.active:hover,
  .show > &.dropdown-toggle,
  .show > &.dropdown-toggle:focus,
  .show > &.dropdown-toggle:hover {
      background-color: $transparent-bg;
      color: $btn-states-color;
      border-color: $btn-states-color;
      box-shadow: none;
  }
}



@mixin btn-size($padding-vertical, $padding-horizontal, $font-size, $border){
   font-size: $font-size;
   border-radius: $border;
   padding: $padding-vertical $padding-horizontal;

   &[class*="btn-outline-"]{
       padding: $padding-vertical - 1 $padding-horizontal - 1;
   }

}
