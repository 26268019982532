.heatmap{
    background-repeat: no-repeat;
    background-size:  100% 100%;
    margin: auto;
    width: 30rem;
    height: 30rem;
    max-width:600px;
    max-height:600px;
    z-index: 2;
    /* object-fit: cover; */
}


.matriz{
    position: absolute;
    margin:auto;
    height: 100%;
    width: 100%;
    max-height: 600px;
    max-width: 600px;
    z-index:-1;
}
.mapa{
    width: 100%;
    height: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.tool{
    position: fixed;
    left: 0;
    top: 0;
    margin: 0;
    width: 60px;
    height: 50px;
    border-radius: 1px;
    display: none;
    z-index: 4;
 
}


.info{ background: rgba(0, 0, 0, .8);
    color: white;
    font-size: 12px;
    padding: 5px;
    line-height: 18px;
    width: 60px;
    height: 30px;
    border-radius: 10px;
}
.cuadrado{
    width: 5px;
    height: 5px;
    background: greenyellow;
    margin: 0 5px 0 5px;
}
.item{
    padding-left: 5px;
    display: flex;
    align-items: center;
    font-style: oblique;
    padding-top: 1px;
    /* justify-content: start; */
    /* justify-items: center; */
}
/* position: "absolute",
            width: "20px",
            height: "20px",
            zIndex: 4, */
#valor{
    background-color: wheat;
}