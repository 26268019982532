img{
    max-width: 100%;
    border-radius: $border-radius-small;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}

.imageDashboard{
    max-height: 30px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// .heatmap{
//     background-image: url('https://smartmetrics.mx/img/heatmapEmpFacil.png');
//     background-repeat: no-repeat;
//     background-size:  100% 100%;
//     margin: auto;
//     width: 100%;
//     height: 50vh;
//     max-width:600px;
//     max-height:600px;
//     z-index: 2;
// }
// //TODO esto es de prueba se tiene que eliminar
// .test-heatmap{
//     background-image: url('https://smartmetrics.mx/img/heatmapEmpFacil.png');
//     background-repeat: no-repeat;
//     background-size:  100% 100%;
//     margin: auto;
//     height: 600px;
//     width: 600px;
//     z-index: 2;
// }
// .matriz{
//     position: absolute;
//     // margin:auto;
//     // background-size:  100% 100%;
//     height: 100%;
//     width: 100%;
//     max-height: 600px;
//     max-width: 600px;
//     z-index:-1;
// }
// .mapa{
//     width: 80vw;
//     height: 80vw;
//     display: flex;
//     // align-items: center;
//     justify-content: center;
// }