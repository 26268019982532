.color-tag::before {
  content: "";
  position: absolute;
  background: #48aaad;
  width: 6px;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0 3px 3px 0;
  transform: translateY(-4px);
}

.color-tag.success::before {
  background: #198754;
}


.color-tag.danger::before {
  background: #dc3545;
}

.color-tag.secondary::before {
  background: #ffffff;
}



