
table.coordenadas{
/* width: 50vw; */
width: 30rem;
height:30rem;
margin: auto;
max-width: 600px;
max-height: 600px;
border-spacing: 0;
border-collapse: collapse;
table-layout: auto;
/* object-fit: cover; */
}
/* tr,td{
    padding:0;
 
    margin: 0;
    border:0;
 
  
} */

button.elementomatriz{
    width: 1px;
    height: 100%;
    padding: 0px;
    border: solid 1px black;
}